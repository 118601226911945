* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	height: 100%;
	width: 100%;
	min-width: 640px;
	margin: 0px;
}


h1 {
	margin-block-start: 0;
	margin-block-end: 0;
}

.App {
	width: 100%;
}

.banner {
	display: flex;
	background-color: rgb(115, 147, 179);
	color: white;
	height: 100px;
	justify-content: center;
	align-items: center;
	padding-top: 1em;
	padding-bottom: 1em;
}

.bannerLogo {
	flex-grow: 0;
	margin-left: 2em;
	/*			background-color: white;*/
	/*			border-radius: 50%;*/
	/*			border-color: white;*/
}

.bannerHeading {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 3em;
	/* max-width: 50vw; */
	/* max-height: 8vw; */
	text-align: center;
	/*			background-color: rgb(204, 204, 255);*/
	flex-grow: 1;
	height: 100%;
	/*			padding-top: 0.3em;*/
	font-family: serif;
	margin-bottom: 1px;
}

.buttons {
	display: flex;
	flex-direction: column;
	margin-right: 2em;
}

.logIn {
	position: relative;
	top: 35%;
	height: 30%;
	width: 6em;
	border: none;
	background-color: rgb(255, 255, 255);
	border-radius: 2px;
	transition: background-color 0.15s;
	color: rgb(15, 82, 186);
	font-family: serif;
	font-size: 16px;
	flex-grow: 0;
	margin-bottom: 1em;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}


.logIn:hover {
	background-color: rgb(135, 206, 235);
}

.signUp {
	position: relative;
	top: 35%;
	height: 30%;
	width: 6em;
	border: none;
	background-color: rgb(255, 255, 255);
	border-radius: 2px;
	transition: background-color 0.15s;
	color: rgb(15, 82, 186);
	font-family: serif;
	font-size: 16px;
	flex-grow: 0;
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.signUp:hover {
	background-color: rgb(135, 206, 235);
}

.navBar {
	display: flex;
	background-color: rgb(96, 130, 182);
	color: rgb(25, 25, 112);
}

.navBar a:link {
	color: rgb(240, 255, 255);
	text-decoration: none;
}

.navBar a:hover {
	background-color: rgb(167, 199, 231);
	color: rgb(25, 25, 112);
}

.navBar a:visited {
	color: white;
}


.navBar a.active {
	color: rgb(25, 25, 112);
}


.active {
	background-color: white;
	/*			color: rgb(96, 130, 182);*/
	color: rgb(25, 25, 112);
}

nav a {
	border: none;
	padding: 10px;
}

.content {
	display: flex;
	flex-direction: column;
	font-family: Segoe UI;
	margin: 1em;
}

.adminContent {
	display: flex;
	flex-direction: row;
	font-family: Segoe UI;
}
/* 
.accounts {
	display: flex;
	flex-direction: row;
	list-style-type: none;
	margin: 0;
 	padding: 0;
  	overflow: hidden;
}

.accountItem {
	display: inline;
	text-align: center;
 	padding-top: 10px;
	padding-right: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
 	text-decoration: none;
	font-size: 16px;
	font-weight: normal;
} */

.accountData {
	margin-left: 20px;
}

#customers {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
  }
  
  #customers td, #customers th {
	border: 1px solid #ddd;
	padding: 8px;
  }
  
  #customers tr:nth-child(even) {
	background-color: #f2f2f2;
}
  
  #customers tr:hover {
	background-color: #ddd;
}
  
  #customers th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: rgb(176, 196, 222);
	color: white;
  }

.actions {
	width: 30px;
}

footer {
	background-color: rgb(182, 208, 226);
	display: flex;
	flex-direction: column;
	/* margin-top: 10px; */
	font-family: Segoe UI;
	width: 100%;
}

.logoName {
	font-family: serif;
}

.columnFooter {
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	align-content: center;
	width: 100%;
	flex-direction: row;
}

.columnFooterLogo {
	flex: 0;
}

.footerVerticalLine {
	width: 10px;
	height: 160px;
	border-right: solid;
	border-right-color: black;
	border-right-width: 2px;
}
.socialMediaLogo {
	/* width: 30px; */
	height: 30px;
	margin-left: 0.5em; 
	margin-right: 0.5em;
	/* flex: 2; */
}

.socialMediaContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.copyright {
	text-align: center;
	margin-top: 1em;
}

footer div {
	margin-top: 10px;
}

footer div a {
	display: block;
	color: black;
	text-decoration: none;
	transition: color 0.15s;
}

footer div a:hover {
	color: white;
}

.between {
	border-bottom-color: rgb(228, 68, 36);
	border-bottom-style: solid;
	border-bottom-width: 0.15em;
}

.footerLogo {
	width: 100px;
}

.highlightSeason {
	font-size: 1.2em;
}

.highlightHeading {
	margin-bottom: 1em;
}

.member-odd {
	display: flex;
	flex-direction: row;
	padding: 10px;
	border: solid;
	border-width: 1px;
	border-color: rgb(220, 220, 220);
	background-color: rgb(230, 245, 245);
	text-align: left;
	font-family: Segoe UI;
	box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.388);
}

.member-even {
	display: flex;
	flex-direction: row;
	padding: 10px;
	border: solid;
	border-width: 1px;
	border-color: rgb(220, 220, 220);
	/* justify-content: space-between; */
	/* align-items: flex-end; */
	background-color: rgb(230, 245, 245);
	text-align: right;
	font-family: Segoe UI;
	box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.388);
}

.member-even > .memberImage {
	flex: 1 0 0%;
}

.member-even > .textRole {
	flex: 1 0 80%;
}

.imageCW {
	float: left;
	margin-right: 10px;
	height: 266px;
}

.member1CW-2 {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.member1CW-1 {
	flex-grow: 0;
	display: flex;
	flex-direction: column;
}

.imageSW {
	float: right;
	margin-left: 10px;
	height: 266px;
}

.name {
	color: rgb(0, 0, 0);
	/*			font-weight: normal;*/
}

.role {
	font-style: italic;
	margin-bottom: 2em;
}

.container {
	display: flex;
	margin-right: 10px;
	margin-left: 10px;
	/*			padding: 10px;*/
	border: solid;
	border-width: 1px;
	border-color: rgb(192, 192, 192);
	background-color: rgb(240, 255, 255);
	text-align: left;
	font-family: Segoe UI;
	flex-direction: column;
	margin-bottom: 1em;
}

.heading {
	padding: 10px;
	color: white;
	background-color: rgb(67, 107, 149);
}

.textContainer {
	padding: 10px;
}



.bulletPoint {
	margin-left: 1em;
	margin-right: 0.5em;
}

.event1 {
	display: flex;
	flex-direction: row;
	margin-top: 1em;
	margin-bottom: 1em;
	box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.388);
}

.date {
	background-color: rgb(152, 157, 170);
	color: white;
	flex-grow: 0;
	padding: 1em;
	text-align: center;
	font-size: 1em;
	min-width: 154px;
}

.description {
	float: right;
	background-color: rgb(228, 241, 255);
	flex-grow: 1;
	padding: 1em;
}

.overview {
	padding-bottom: 0.2em;
}

.viewDetails {
	/* margin-top: 0.2em; */
	background-color: azure;
	padding: 0.1em;
	text-decoration: none;

}

.sponsorContainer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.sponsorImage {
	display: block;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 1em;
	max-height: 200px;
	max-width: 400px;
}

.seasonContent {
	display: flex;
	flex-direction: column;
}

.aboutUs {
	display: flex;
	margin-right: 10px;
	margin-left: 10px;
	/*			padding: 10px;*/
	border: solid;
	border-width: 1px;
	border-color: rgb(192, 192, 192);
	background-color: rgb(240, 255, 255);
	text-align: left;
	font-family: Segoe UI;
}

.aboutHeading {
	padding: 10px;
	/*			background-color: black;*/
	/*			color: white;*/
	color: white;
	background-color: rgb(67, 107, 149);
	/*background-color: rgb(42,82,190);*/
}

.float-left {
	float: left;
	margin-right: 10px;
	width: 300px;
}

.margin-top-bottom-10 {
	margin-top: 10px;
	margin-bottom: 10px;
}
.float-right {
	float: right;
	margin-right: 10px;
	width: 300px;
}
.member {
	padding: 10px;
	border: solid;
	margin-bottom: 20px;
	border-width: 1px;
	border-color: rgb(220, 220, 220);
	background-color: rgb(230, 245, 245);
	text-align: left;
	font-family: Segoe UI;
	box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.388);
}
.slider {
    align-items: center;
    border: 100px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    justify-content: center;
    padding-bottom: 20px;
    width: 90%;
}

.slide.active {
    opacity: 1;
    transition-duration: 1s;
}

.aboutText {
	padding: 10px;
}

.about {
	display: flex;
	flex-direction: column;
	box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.388);
	/* border-color: rgb(220, 220, 220); */
	/* border: solid; */
	/* border-width: 0.5px; */
}

.goal {
	margin-top: 1em;
	display: flex;
	margin-right: 10px;
	margin-left: 10px;
	/*			padding: 10px;*/
	border: solid;
	border-width: 1px;
	border-color: rgb(192, 192, 192);
	background-color: rgb(240, 255, 255);
	text-align: left;
	font-family: Segoe UI;
}

.seasonContainer {
	display: flex;
	margin-top: 1em;
	margin-right: 10px;
	margin-left: 10px;
	border: solid;
	border-width: 1px;
	border-color: rgb(192, 192, 192);
	background-color: rgb(240, 255, 255);
	text-align: left;
	font-family: Segoe UI;
	flex-direction: column;
	box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.388);
}

.seasonHeading {
	color: white;
	background-color: rgb(67, 107, 149);
	padding: 10px;
}

.qual1 {
	background-color: rgb(240, 248, 255);
	padding: 10px;
}

.qual2 {
	background-color: rgb(188, 212, 230);
	padding: 10px;
}

.year {
	font-size: 1em;
	font-style: italic;
	margin-bottom: 1em;
}

.robot {
	padding: 1em;
	display: flex;
	flex-direction: row;
}

.robotImage {
	flex-grow: 1;
}

.robotText {
	flex-grow: 1;
	margin-right: 0.5em;
}

.heading {
	padding-left: 1em;
	padding-right: 1em;
}



.season {
	background-color: rgb(240, 255, 255);
	/* padding: 1em; */
	/* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
	box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.388);
	margin-bottom: 2em;
	display: flex;
	flex-direction: column;
	margin-top: 1em;
	margin-right: 10px;
	margin-left: 10px;
	border: solid;
	border-width: 1px;
	border-color: rgb(192, 192, 192);
	width: 100%;
}

.seasonHeadings {
	background-color: rgb(167, 199, 231);
	padding: 1em;
	display: flex;
	flex-direction: column;
	/* margin-bottom: 1em; */
}

.outreachSeason {
	font-size: 1.5em;
}

.login {
	text-align: center;
	/* background-color:rgb(115, 147, 179); */
	background-color: white;
	width: 20vw;
	height: 20vw;
	/* box-shadow: 10px 5px 10px rgba(0, 0, 0, 0.388); */
	border-radius: 50px;
	border: solid 1px rgb(220, 220, 220);
	margin: auto;
}

.input1 {
	margin: auto;
}

.input2 {
	margin-top: 20px;
}

.loginInput {
	margin-top: 76.5px;
}

.forgotPassword {
	color: rgb(96, 130, 182);
	text-decoration: none;
	font-family: Segoe UI;
	font-size: 0.9em;
	transition: color 0.2s;
}

.forgotPassword:hover {
	color: black;
}

.input {
	/* padding: 10px; */
	height: 31px;
	padding-left: 1em;
	margin-bottom: 0.5em;
	margin-top: 0.5em;
	width: 13vw;
}

.loginButton {
	padding-top: 0.8em;
	padding-bottom: 0.8em;
	padding-left: 8.5em;
	padding-right: 8.5em;
	border-radius: 17.5px;
	font-family: sans-serif;
	border: none;
	margin-top: 0.5em;
	background-color: rgb(96, 130, 182);
	color: white;
	font-size: 0.8em;
	margin-bottom: 1em;
	transition: color 0.15s;
	cursor: pointer;
}

.loginButton:hover {
	background-color: rgb(76, 110, 162);
}

.sideBar {
	display: flex;
	flex-direction: column;
	background-color: aliceblue;
	width: 100px;
}

.sideBarChild {
	padding: 10px;
	text-decoration: none;
	color: black;
	border-bottom: solid 1px black;
	border-right: solid 1px black;
}

.image {
    border-radius: 10px;
	min-height:320px;
    height: 40vw;
    margin: 30px;
    width: auto;
}

@media only screen and (max-width: 720px) {
	.bannerHeading {
		font-size: 2.6em;
	}
  }

  @media only screen and (max-width: 640px) {
	.bannerHeading {
		font-size: 1.6em;
	}
}

  @media only screen and (max-width: 480) {
	.bannerHeading {
		font-size: 1.3em;
	}
  }